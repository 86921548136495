import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { Line, Polar, Bar } from 'react-chartjs-2';
import moment from 'moment';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';


class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeClients: 0,
      last7DaysVisits: {
        labels: ['Today', 'Yesterday', '', '', '', '', ''],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      },
      last24HoursVisits: {
        labels: ['', '', '', '', '', '', ''],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      },
      salesManagersStatistics: {
        labels: ['', '', '', '', '', '', ''],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      },
      ordersStatistics: {
        labels: ['', '', '', '', '', '', ''],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      },
      productVisits: []
    };
  }


  componentDidMount() {
    //this.props.handleLoader(true);
    if (this.props.socketIOClient && this.props.uData) {
      this.registerSocketIOEvents();
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.socketIOClient && this.props.uData) {
      this.registerSocketIOEvents();
    }
  }


  registerSocketIOEvents() {
    if (this.state._registeredEvents)
      return;

    this.setState({
      _registeredEvents: true
    });



    this.props.socketIOClient.on('adminFetch', (data) => {
      console.log(data);
      this.props.handleLoader(null);
      this.setState({
        results: data.result,
        count: data.count,
        imageErrors: {}
      });
    });

    this.props.socketIOClient.on('adminActiveClients', (data) => {
      console.log(data);
      this.setState({
        activeClients: data.count
      })
    });

    this.props.socketIOClient.on('adminStatistic', (data) => {
      console.log(data);

      let last7DaysVisits = {
        labels: ['Today', 'Yesterday', moment.unix(data.days[2].timestamp).format('DD.MM.YYYY'), moment.unix(data.days[3].timestamp).format('DD.MM.YYYY'), moment.unix(data.days[4].timestamp).format('DD.MM.YYYY'), moment.unix(data.days[5].timestamp).format('DD.MM.YYYY'), moment.unix(data.days[6].timestamp).format('DD.MM.YYYY')].reverse(),
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      };



      for (let i = 0; i < data.days.length; i++) {
        last7DaysVisits.datasets[0].data.push(data.days[i].count);
      }

      last7DaysVisits.datasets[0].data = last7DaysVisits.datasets[0].data.reverse();



      let last24HoursVisits = {
        labels: [],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      };


      for (let i = 0; i < data.hours.length; i++) {
        last24HoursVisits.datasets[0].data.push(data.hours[i].count);
        last24HoursVisits.labels.push(moment.unix(data.hours[i].timestamp).format('HH:mm'));
      }


      let salesManagersStatistics = {
        labels: [],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      };

      if (data.salesManagers) {
        for (let i = 0; i < data.salesManagers.length; i++) {
          salesManagersStatistics.datasets[0].data.push(data.salesManagers[i].total);
          salesManagersStatistics.labels.push(data.salesManagers[i].User.Name);
        }
      }

      let ordersStatistics = {
        labels: [],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      };

      if (data.ordersStatistics){
        for (let i = 0; i < data.ordersStatistics.length; i++) {
          ordersStatistics.datasets[0].data.push(data.ordersStatistics[i].total);
          ordersStatistics.labels.push(data.ordersStatistics[i].name);
        }
    }

      data.ordersStatistics = ordersStatistics;
    data.last7DaysVisits = last7DaysVisits;
    data.last24HoursVisits = last24HoursVisits;
    data.salesManagersStatistics = salesManagersStatistics;

    this.setState(data);
  });

    this.props.socketIOClient.emit('adminActiveClients', {});


// Get a date object for the current time
var d = new Date();

// Set it to one month ago
d.setMonth(d.getMonth() - 1);
d.setDate(1);
// Zero the hours
d.setHours(0, 0, 0);
// Zero the milliseconds
d.setMilliseconds(0);


let lastMonthStartDate = d / 1000;

this.setState({
  from: moment.unix(lastMonthStartDate).format('DD.MM.YYYY'),
  to: moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY')
})

this.props.socketIOClient.emit('adminStatistic', { from: lastMonthStartDate, to: Math.floor(new Date().getTime() / 1000) });

  }


componentWillUnmount() {
  if (!this.props.socketIOClient) return;
  this.props.socketIOClient.removeAllListeners("adminFetch");
}


render() {

  return (
    <div className="content">
      {

        (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

      }

      <div className="panel ">
        <div className="panel-title">
          <h1>{this.props.translate('Dashboard')}</h1>
          <h6>{this.props.translate('Statistika websajta')}</h6>

        </div>


      </div>


      <Container fluid>

        <Row>
          <Col lg="6">
            <Container>
              <Row>
                <Col lg="3" xs="6">
                  <div className="box">
                    <div>
                      <i className="mdi mdi-account-network"></i>
                      <h6>{this.props.translate('Trenutna posjeta')}</h6>
                      <p>{this.state.activeClients}</p>
                    </div>
                  </div>
                </Col>

                <Col lg="3" xs="6">

                  <div className="box">
                    <div>
                      <i className="mdi mdi-package-variant-closed"></i>
                      <h6>{this.props.translate('Narudžbe')}</h6>
                      <p>{this.state.orders}</p>
                    </div>
                  </div>
                </Col>

                <Col lg="3" xs="6">

                  <div className="box">
                    <div>
                      <i className="mdi mdi-package-variant"></i>
                      <h6>{this.props.translate('Proizvodi')}</h6>
                      <p>{this.state.products}</p>
                    </div>
                  </div>
                </Col>

                <Col lg="3" xs="6">


                  <div className="box">
                    <div>
                      <i className="mdi mdi-account"></i>
                      <h6>{this.props.translate('Korisnici')}</h6>
                      <p>{this.state.users}</p>
                    </div>
                  </div>
                </Col>

                <Col lg="12">

                  <div className="chartjs-panel">
                    <div>
                      <div className="container-title">
                        <h3>{this.props.translate('Posjete websajta')}</h3>
                        <h6>{this.props.translate('Poslednjih 7 dana')}</h6>

                      </div>


                      <Line data={this.state.last7DaysVisits} options={{
                        responsive: true,
                        legend: { display: false },
                        scales: {
                          xAxes: [{
                            gridLines: {
                              display: false
                            }
                          }],
                          yAxes: [{
                            gridLines: {
                              drawBorder: false
                            }
                          }]

                        },

                      }} />


                    </div>
                  </div>
                </Col>


                <Col lg="12">

                  <div className="chartjs-panel">
                    <div>
                      <div className="container-title">
                        <h3>{this.props.translate('Posjete websajta')}</h3>
                        <h6>{this.props.translate('Danas')}</h6>

                      </div>


                      <Line data={this.state.last24HoursVisits} options={{
                        responsive: true,
                        legend: { display: false },
                        scales: {
                          xAxes: [{
                            gridLines: {
                              display: false
                            }
                          }],
                          yAxes: [{
                            gridLines: {
                              drawBorder: false
                            }
                          }]

                        },

                      }} />


                    </div>
                  </div>
                </Col>
{/*
                <Col lg="12">

                  <div className="chartjs-panel">
                    <div>
                      <div className="container-title">
                        <h3>{this.props.translate('Narudžbe komercijalista')}</h3>
                        <h6>{this.props.translate('Vrijednost u KM')}</h6>

                        <div>
                          <input type='text' placeholder="Od" value={this.state.from} onChange={(e) => this.setState({ from: e.target.value })} />
                          <input type='text' placeholder="Do" value={this.state.to} onChange={(e) => this.setState({ to: e.target.value })} />
                          <button className="button" onClick={() => {
                            let to = Math.floor(new Date(this.state.to.split('.')[2], this.state.to.split('.')[1], this.state.to.split('.')[0]).getTime() / 1000)
                            let from = Math.floor(new Date(this.state.from.split('.')[2], this.state.from.split('.')[1], this.state.from.split('.')[0]).getTime() / 1000)


                            this.props.socketIOClient.emit('adminStatistic', { from: from, to: to });


                          }}>{this.props.translate('Prikaži')}</button>

                        </div>
                      </div>


                      <Bar data={this.state.salesManagersStatistics} options={{
                        responsive: true,
                        legend: { display: false },
                        scales: {
                          xAxes: [{
                            gridLines: {
                              display: false
                            }
                          }],
                          yAxes: [{
                            gridLines: {
                              drawBorder: false
                            }
                          }]

                        },

                      }} />


                    </div>
                  </div>
                    </Col>*/}

                <Col lg="12">

                  <div className="chartjs-panel">
                    <div>
                      <div className="container-title">
                        <h3>{this.props.translate('Promet')}</h3>
                        <h6>{this.props.translate('Vrijednost u KM')}</h6>

                        <div>
                          <input type='text' placeholder="Od" value={this.state.from} onChange={(e) => this.setState({ from: e.target.value })} />
                          <input type='text' placeholder="Do" value={this.state.to} onChange={(e) => this.setState({ to: e.target.value })} />
                          <button className="button" onClick={() => {
                            let to = Math.floor(new Date(this.state.to.split('.')[2], this.state.to.split('.')[1], this.state.to.split('.')[0]).getTime() / 1000)
                            let from = Math.floor(new Date(this.state.from.split('.')[2], this.state.from.split('.')[1], this.state.from.split('.')[0]).getTime() / 1000)


                            this.props.socketIOClient.emit('adminStatistic', { from: from, to: to });


                          }}>{this.props.translate('Prikaži')}</button>

                        </div>
                      </div>


                      <Bar data={this.state.ordersStatistics} options={{
                        responsive: true,
                        legend: { display: false },
                        scales: {
                          xAxes: [{
                            gridLines: {
                              display: false
                            }
                          }],
                          yAxes: [{
                            gridLines: {
                              drawBorder: false
                            }
                          }]

                        },

                      }} />


                    </div>
                  </div>
                </Col>


              </Row>
            </Container>
          </Col>
          <Col lg="6" xs="12">
            <div className="container-title">
              <h3>{this.props.translate('Poslednje pregledano')}</h3>
            </div>

            <div className='table'>
              <table>

                <tr>
                  <th></th>
                  <th>{this.props.translate('Alias')}</th>
                  <th>{this.props.translate('Name')}</th>

                  <th></th>
                </tr>

                {
                  this.state.productVisits.map((item, idx) => {
                    if (item)
                      return (
                        <tr key={idx}>
                          <td><img src={item.Images && item.Images[0]} /></td>
                          <td>{item.Alias} </td>
                          <td>{item.Name ? item.Name : ''} </td>

                        </tr>
                      )
                  })
                }

              </table>

            </div>
          </Col>
        </Row>
      </Container>

    </div>



  );
}
}

export default PageWithLayout(HomePage);