import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Form from '../components/forms/form';

import MessageBox from '../components/messageBox';


class FormPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.search = this.search.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);

        this.state = {
            lang: 'ba',
            initialValues: null,
            linkedCollection: {},
            fields: [],
            linkedFields: [],
            searchResults: {}
        };
    }


    componentDidMount() {


        this.setState({
            fields: this.props.fields,
            linkedFields: this.props.linkedFields
        }, () => {
            if (this.props.socketIOClient && this.props.uData) {
                this.registerSocketIOEvents();
            }
        })



    }

    componentWillReceiveProps(nextProps) {
        if (this.props[0].location.pathname !== nextProps[0].location.pathname) {
            this.setState({
                lang: 'de',
                initialValues: null,
                linkedCollection: {},
                fields: nextProps.fields,
                linkedFields: nextProps.linkedFields
            }, () => {
                for (let i = 0; i < this.state.linkedFields.length; i++) {
                    console.log(this.state.linkedFields[i].linkedCollection);
                    this.props.socketIOClient.emit('adminFetch', {
                        collection: this.state.linkedFields[i].linkedCollection,
                        query: {},
                        sort: { _id: 1 },
                        skip: 0
                    })
                }





                if (nextProps[0].match.params.id !== 'new')
                    this.props.socketIOClient.emit('adminFetchOne', { collection: nextProps.collection, query: { _id: nextProps[0].match.params.id } });
                else
                    this.setState({ initialValues: {} });

            });
        }
    }



    componentDidUpdate(prevProps) {
        console.log(this.props.uData);
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }


        let fields = this.state.fields;
        let linkedFields = this.state.linkedFields;
        console.log(linkedFields, this.state.linkedCollection);
        let changed;
        for (let i = 0; i < linkedFields.length; i++) {
            if (!linkedFields[i]._attached && this.state.linkedCollection[linkedFields[i].linkedCollection]) {
                linkedFields[i]._attached = true;
                linkedFields[i].values = linkedFields[i].renderItems(this.state.linkedCollection[linkedFields[i].linkedCollection]);
                delete linkedFields[i].renderItems;
                fields.push(linkedFields[i]);
                if (this.state.initialValues && this.state.initialValues[linkedFields[i].name])
                    linkedFields[i].additionalAction(this, this.state.initialValues[linkedFields[i].name])

                changed = true;
            }
        }

        //alert(changed);

        if (changed) {
            this.setState({
                linkedFields,
                fields
            })
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('adminFetchOne', (data) => {
            console.log(data);
            this.setState({
                initialValues: data
            }, () => {
                let fields = this.state.fields;
                for (let i = 0; i < fields.length; i++) {
                    if ( (fields[i].type === 'select' || fields[i].type === 'category')  && fields[i].renderItems) {
                        fields[i].values = fields[i].renderItems(this.state.initialValues);
                    }
                }

                this.forceUpdate();
            })
        });

        this.props.socketIOClient.on('adminUpdate', (data) => {
            if (data.successful) {
                this.props.handleLoader(null);
                if (this.props[0].match.params.id === '__') {
                    this.props[0].history.push('/');
                } else {
                    this.props[0].history.push(this.props.link);
                }
            } else {
                this.setState({
                    error: data.message
                })
            }
        });


        this.props.socketIOClient.on('adminFetch', (data) => {
            console.log(data);
            if (!data.fetchId) {
                let linkedCollection = this.state.linkedCollection;
                linkedCollection[data.collection] = data.result;
                this.setState({
                    linkedCollection: linkedCollection
                })
            } else {
                let searchResults = this.state.searchResults;
                searchResults[data.fetchId] = data.result;
                this.setState({
                    searchResults: searchResults
                })
            }
        });

        for (let i = 0; i < this.state.linkedFields.length; i++) {
            console.log(this.state.linkedFields[i].linkedCollection);
            this.props.socketIOClient.emit('adminFetch', {
                collection: this.state.linkedFields[i].linkedCollection,
                query: {},
                sort: { _id: 1 },
                skip: 0
            })
        }





        if (this.props[0].match.params.id !== 'new' && this.props[0].match.params.id !== '__')
            this.props.socketIOClient.emit('adminFetchOne', { collection: this.props.collection, query: { _id: this.props[0].match.params.id } });
        else if (this.props[0].match.params.id === '__') {
            //this.setState({ initialValues: {} });
            this.props.socketIOClient.emit('adminFetchOne', { collection: this.props.collection, query: {} });
        } else {
            this.setState({ initialValues: {} });
        }


    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetchOne");
    }


    handleSubmit(data) {
        console.log(data);

        delete data._id;
        let obj = {
            collection: this.props.collection,
            query: { _id: this.props[0].match.params.id },
            data: data
        }

        //this.props.handleLoader(true);

        this.props.socketIOClient.emit("adminUpdate", obj);
    }



    search(collection, fields, limit, id, value) {

        if (value === '') {
            let searchResults = this.state.searchResults;
            searchResults[id] = null;
            this.setState({
                searchResults: searchResults
            })
            return;
        }

        let obj = {
            collection: collection,
            query: {},
            limit: limit,
            skip: 0,
            sort: {},
            fetchId: id,
            search: { fields: fields, value: value },
        };

        this.props.socketIOClient.emit('adminFetch', obj);
    }

    handleSearchClick(id, item, callback) {
        let searchResults = this.state.searchResults;
        searchResults[id] = null;
        this.setState({
            searchResults: searchResults
        })

        callback(this, item);
    }


    render() {



        return (
            <div className="content">
                {

                    (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

                }
                <div className="panel ">
                    <Container fluid className="panel-title">
                        <h1>{this.props.translate(this.props.title)}</h1>
                        <h6>{this.props.translate(this.props.subtitle)}</h6>

                    </Container>

                    {this.props.multilang ?
                        <ul className="multilang-field">
                            {
                                this.props.langs.map((lang, idx) => {
                                    return (
                                        <li className={this.state.lang === lang ? 'active' : null} onClick={() => { this.setState({ lang: lang }) }}>{lang}</li>
                                    )
                                })
                            }
                        </ul>

                        : null
                    }
                    <Container fluid>

                        <Row>
                            <Col lg="12">

                                {this.state.initialValues ? <Form handleSearchClick={this.handleSearchClick} searchResults={this.state.searchResults} search={this.search} initialValues={this.state.initialValues} scope={this} onSubmit={(data) => { this.handleSubmit(data) }} fields={this.state.fields} lang={this.state.lang} translate={this.props.translate} socketIOClient={this.props.socketIOClient} _googleMapsLoaded={this.props._googleMapsLoaded}></Form> : null}

                            </Col>
                        </Row>
                    </Container>

                </div>
                {this.state.error ?
                    <MessageBox message={this.state.error} handleClose={() => this.setState({ error: null })}></MessageBox>
                    : null
                }

            </div>
        );
    }
}

export default PageWithLayout(FormPage);